import { getWindow } from "../../../utils/browser-features";
import { HealthQuestionnaireDTO } from "../domain/health-questionnaire";

export const getHealthQuestionnaire = (): HealthQuestionnaireDTO | null => {
  const w: Window | null = getWindow();

  if (!w) {
    return null;
  }

  const healthQuestionnaire: string | null = w.localStorage.getItem("healthQuestionnaire");

  if (!healthQuestionnaire) {
    return null;
  }

  return JSON.parse(healthQuestionnaire) as HealthQuestionnaireDTO;
};

export const updateHealthQuestionnaire = (healthQuestionnaire: string): void => {
  const w: Window | null = getWindow();

  if (!w) {
    return;
  }

  w.localStorage.setItem("healthQuestionnaire", healthQuestionnaire);
};
