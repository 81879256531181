import { useRecoilValue, useSetRecoilState } from "recoil";

import { HealthQuestionnaire, HealthQuestionnaireDTO } from "../domain/health-questionnaire";
import { updateHealthQuestionnaire } from "../repositories/health-questionnaire-repository";
import { healthQuestionnaireState } from "../state/atoms";

export const useHealthQuestionnaire = (): HealthQuestionnaire => {
  const healthQuestionnaire: HealthQuestionnaireDTO = useRecoilValue(healthQuestionnaireState);

  return new HealthQuestionnaire(healthQuestionnaire);
};

export function useSetHealthQuestionnaire(): (values: HealthQuestionnaireDTO) => Promise<void> {
  const healthQuestionnaire: HealthQuestionnaireDTO = useRecoilValue(healthQuestionnaireState);
  const healthQuestionnaireSetter = useSetRecoilState(healthQuestionnaireState);

  return async (values: HealthQuestionnaireDTO): Promise<void> => {
    try {
      const newHealthQuestionnaire: HealthQuestionnaireDTO = {
        ...healthQuestionnaire,
        ...values,
      };

      healthQuestionnaireSetter(newHealthQuestionnaire);
      updateHealthQuestionnaire(JSON.stringify(newHealthQuestionnaire));

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };
}
