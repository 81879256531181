export interface HealthQuestionnaireDTO {
  preExistences?: string;
}

export class HealthQuestionnaire implements HealthQuestionnaireDTO {
  readonly preExistences: string;

  constructor(attrs: HealthQuestionnaireDTO) {
    Object.assign(this, attrs);
  }
}
