import { atom, RecoilState } from "recoil";

import { HealthQuestionnaireDTO } from "../domain/health-questionnaire";
import { getHealthQuestionnaire } from "../repositories/health-questionnaire-repository";

const healthQuestionnaireInStorage: HealthQuestionnaireDTO | null = getHealthQuestionnaire();

const getHealthQuestionnaireDefault = (): HealthQuestionnaireDTO => {
  if (healthQuestionnaireInStorage) {
    return {
      ...healthQuestionnaireInStorage,
    };
  }

  return {
    preExistences: "",
  };
};

export const healthQuestionnaireState: RecoilState<HealthQuestionnaireDTO> =
  atom<HealthQuestionnaireDTO>({
    key: "healthQuestionnaire",
    default: getHealthQuestionnaireDefault(),
  });
