import { graphql } from "gatsby";

import PreExistences from "../../../organisms/health-questionnaire/PreExistences";
import OnboardingLayout from "../../../organisms/lead/OnboardingLayout";

const PreExistingConditionsPage = (): JSX.Element => (
  <OnboardingLayout>
    <PreExistences />
  </OnboardingLayout>
);

export default PreExistingConditionsPage;

export const query = graphql`
  query PreExistingConditionsPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
