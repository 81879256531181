import classNames from "classnames";
import { ButtonHTMLAttributes, ReactNode } from "react";

import { States } from "../../settings/state";
import SpinnerLoader from "../spinner-loader/SpinnerLoader";
import * as styles from "./Button.module.scss";

export enum ButtonVariantName {
  secondary = "secondary",
}

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variantName?: ButtonVariantName;
  adoptionClassName?: string;
  isLoading?: boolean | States;
}
const Button = ({
  children,
  variantName,
  adoptionClassName,
  isLoading,
  ...props
}: ButtonProps): JSX.Element => {
  const componentName = "at-button";

  return (
    <>
      <button
        className={classNames(componentName, styles.button, adoptionClassName, {
          [styles.isLoading as string]: isLoading,
          [styles[variantName as string]]: variantName,
        })}
        {...props}
      >
        {isLoading ? <SpinnerLoader></SpinnerLoader> : children}
      </button>
    </>
  );
};

export default Button;
